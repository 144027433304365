<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { _ } from "svelte-i18n";
  import { AuthenticationService } from "../../services/auth";
  import { debounce } from "../../utils/debounce";
  import { checkRequiredFields, requireFields } from "../../utils/form-utils";
  import { validatePassword } from "../../utils/validation-password";
  import { Form, ResetPasswordForm } from "../../types/Form";
  import LoadingButton from "../lib/LoadingButton.svelte";
  import EntryScreen from "../lib/EntryScreen.svelte";
  import Input from "../lib/Input.svelte";

  export let params: URLSearchParams;

  const authSvc = new AuthenticationService();
  const form: Form<ResetPasswordForm> = new Form({
    email: null,
    token: null,
    password: null,
    passwordConfirm: null,
  });

  let loading = false;
  let isLinkBroken = false;
  let pwdErrors = [];

  onMount(async () => {
    // get email and token from url
    if (params.get("email") && params.get("token")) {
      form.value.email = params.get("email");
      form.value.token = params.get("token");

      // try token
      if (!(await authSvc.checkToken(form.value.email, form.value.token))) {
        isLinkBroken = true;
      }
    } else {
      isLinkBroken = true;
    }
  });

  async function handleSubmit() {
    loading = true;
    validate();
    form.submitted = true;
    if (form.valid) {
      let success = await authSvc.resetPassword(form.value);
      if (success) {
        authSvc.prepareAfterResetLogin({
          email: form.value.email,
          password: form.value.password,
        });
        setTimeout(() => {
          loading = false;
          navigate("/login");
        }, 200);
      }
    }
    loading = false;
  }

  const debouncedValidation: () => void = debounce(250, validate);

  function validate() {
    pwdErrors = validatePassword(form.value.password, $_);
    form.valid =
      checkRequiredFields(form.value) && !pwdErrors.length && form.value.password === form.value.passwordConfirm;
    form.errors = requireFields(form.value);
    form.errors["passwordMatch"] = form.value.password !== form.value.passwordConfirm;
  }
</script>

<EntryScreen>
  {#if isLinkBroken}
    <div class="text-center px-4">
      <h2>
        {$_("login.broken_link_1")}
        <br>
        {$_("login.broken_link_2")}
      </h2>
      <h5>
        {$_("login.broken_link_suggestion_1")}
        <a href='https://sifa-pazienti.sinapto.tech'>QUI</a>
        {$_("login.broken_link_suggestion_2")}
      </h5>
    </div>
  {:else}
    <div class="card">
      <div class="card-body p-4">
        <h1>{$_("login.reset_password")}</h1>
        <form class:submitted={form.submitted}>
          <input bind:value={form.value.email} class="form-control" type="email" id="register-email" hidden />
          <input bind:value={form.value.token} class="form-control" type="text" id="register-token" hidden />

          <div class="mb-3">
            <label for="register-password">{$_("login.password")}</label>
            <Input
              bind:value={form.value.password}
              on:input={debouncedValidation}
              errors={form.errors["password"]}
              type="password"
              id="register-password"
            />
          </div>

          <div class="mb-3">
            <label for="register-password-confirm">{$_("login.confirm_password")}</label>
            <Input
              bind:value={form.value.passwordConfirm}
              on:input={debouncedValidation}
              errors={form.errors["passwordConfirm"]}
              type="password"
              id="register-password-confirm"
            />
          </div>

          {#if form.errors["passwordMatch"]}
            <div class="mb-3 form-error">{$_(`forms.password_match`)}</div>
          {/if}

          {#if pwdErrors && pwdErrors.length > 0}
            <div class="mb-3">
              {#each pwdErrors as err}
                <div class="form-error">
                  {err}
                </div>
              {/each}
            </div>
          {/if}

          <LoadingButton on:click={handleSubmit} {loading} classes="w-100 text-capitalize">
            {$_("login.confirm")}
          </LoadingButton>
        </form>
      </div>
    </div>
  {/if}
</EntryScreen>
